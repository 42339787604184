* {
  margin: 0;
  box-sizing: border-box;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent;
  display: none;
}

body {
  overscroll-behavior: none;
  -ms-overflow-style: none;
  font-size: 16px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.unlist {
  list-style: none;
}

.thumbnails {
  position: relative;
  display: flex;
}
.thumbnails:not(:last-child) {
  margin-right: 45px;
}
.thumbnail {
  height: 40vh;
  width: 700px;
}

.img-hover-zoom {
  overflow: hidden; /* [1.2] Hide the overflowing of child elements */
  width: 70%;
  width: auto;
  height: auto;
}

/* [2] Transition property for smooth transformation of images */
.img-hover-zoom img {
  transition: transform 0.8s ease;
  margin-bottom: 0;
  width: 100%;
  height: 100%;
}

/* [3] Finally, transforming the image when container gets hovered */
.img-hover-zoom:hover img {
  transform: scale(1.1);
}

/* //////////////////// */
/* SCROLL CLASSES */

.child {
  width: 100vw;
  height: auto;
  background-color: red;
}
.bgImg {
  background-size: cover;
  background-position: center;
}

/* .flex {
  display: flex;
}
.column {
  flex-direction: column;
} */

/* .centerH {
  justify-content: center;
}
.centerV {
  align-items: center;
}
.flexWrap {
  flex-wrap: wrap;
}
.flexEnd {
  justify-content: flex-end;
} */

/* //////////////////// */
/* SLIDER CLASSES */

.example-container {
  /* width: 100vw;
  height: 100vh;
  position: relative; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.next,
.prev {
  /* top: calc(50% - 20px); */
  /* position: absolute; */
  background: transparent;
  border: solid #331c65 1px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  /* z-index: 2; */
}

.next {
  /* right: 10px; */
}

.prev {
  /* left: 10px; */
  transform: scale(-1);
}

.sliderImage {
  /* position: absolute; */
  width: 34%;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
  /* position: absolute; */
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}

.unsetWidth {
  width: unset !important;
}

/* //////////////WONRGGGGGGGGGG ////////////// */

.home {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.home p {
  font-size: 0.75rem;
  letter-spacing: 0.3rem;
  text-transform: uppercase;
  font-weight: 500;
}
.home h5 {
  margin-top: 260px;
  font-size: 24px;
  font-weight: 600;
  padding-right: 360px;
}

.logo a {
  font-weight: 700;
  font-size: 1rem;
  text-decoration: none;
  color: "#b3c53f";
}
.menu button {
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  font-size: 0.8rem;
  mix-blend-mode: difference;
  font: bold 20px/20px;
}

.hamburger-menu {
  display: none;
  z-index: 9;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #431e57;
}

.menu-secondary-background-color {
  background: #2f123d;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.menu-layer {
  position: relative;
  background: #1d0d25;
  height: 100%;
  overflow: hidden;
}

.menu-city-background {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  background-size: cover;
  background-repeat: no-repeat;
  animation-name: backgroundEffect;
  animation-duration: 30s;
  animation-iteration-count: infinite;
}
.wrapper {
  position: relative;
}

.menu-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  top: 200px;
}

.info {
  color: #fff;
  width: 300px;
}

.info h3 {
  font-size: 1.2rem;
  margin: 8px auto;
}
.info p {
  margin: 0 auto;
  font-size: 0.8rem;
}

.locations {
  position: absolute;
  bottom: -80px;
  color: #fff;
  margin-top: 16px;
  font-size: 0.8rem;
  font-weight: 600;
}

.locations span {
  cursor: pointer;
  font-weight: 400;
  margin: 0 32px;
  transition: 0.3s ease-in-out;
}
@keyframes backgroundEffect {
  0% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 40% 10%;
  }
  50% {
    background-position: 0 10%;
  }
  75% {
    background-position: 10% 40%;
  }
  100% {
    background-position: 0% 0%;
  }
}

/* /////////// GALLERY ///////////// */

/* .image-1 {
  width: 30vw;
  left: 16%;
  bottom: 10%;
}
.image-2 {
  width: 20vw;
  right: 12%;
  top: 12%;
}
.image-3 {
  width: 26vw;
  right: 20%;
  bottom: 4%;
}
.image-4 {
  width: 24vw;
  right: 22%;
  top: 29%;
}
.image-5 {
  width: 23vw;
  left: 6%;
  top: 16%;
}
.image-6 {
  width: 20vw;
  left: 24%;
  top: 38%;
}
.image-7 {
  width: 18vw;
  left: 40%;
  top: 20%;
}
.image-8 {
  width: 23vw;
  right: 8%;
  top: 50%;
}
.image-9 {
  width: 24vw;
  left: 20%;
  top: 8%;
} */

.mementos-0 {
  width: 30vw;
  left: 30%;
  top: 30%;
  z-index: 8;
}
.mementos-1false {
  width: 16vw;
  right: 6%;
  top: -1%;
z-index:2;
  transform: rotate(20deg);
}
.mementos-5 {
  width: 20vw;
  left: 24%;
  top: 2%;
  transform: rotate(-18deg);
  z-index: 1;
}

.mementos-2 {
  width: 14vw;
  right: 28%;
  top: 10%;
  z-index: 1;
}
.mementos-4false {
  width: 28vw;
  left: 41%;
  bottom: 13%;
  z-index: 3;
}
.mementos-5false {
  width: 12vw;
  left: 14%;
  top: 33%;
  transform: rotate(-30deg);
  z-index: 5;
}
.mementos-6 {
  width: 24vw;
  left: 8%;
  bottom: 5%;
  transform: rotate(-32deg);
  z-index: 4;
}
.mementos-8 {
  width: 22vw;
  right: -7%;
  bottom: 1%;
  transform: rotate(38deg);
  z-index: 2;
}
.mementos-9false {
  width: 26vw;
  left: 39%;
  bottom: -5%;
  z-index: 3;
  transform: rotate(165deg);
}

.mementos-0:hover,
.mementos-1false:hover,
.mementos-5:hover,
.mementos-2:hover,
.mementos-4false:hover,
.mementos-5false:hover,
.mementos-6:hover,
.mementos-8:hover,
.mementos-9false:hover {
  z-index: 10;
}

.mementos-6:hover {
  transform: rotate(90deg);
  transition: transform 0.3s ease-in-out;
}
.mementos-9false:hover {
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out;
}

/* .image-1:hover,
.image-2:hover,
.image-3:hover,
.image-4:hover,
.image-5:hover,
.image-6:hover,
.image-7:hover,
.image-8:hover,
.image-9:hover {
  z-index: 1;
} */

.image-block {
  position: absolute;
  transform-origin: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ////////////////////// */

.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
}

.slideshowSlider-0 {
  transition-delay: 0s;
}
.slideshowSlider-1 {
  transition-delay: 1s;
  display: flex;
  align-items: center;
}
.slideshowSlider-2 {
  transition-delay: 2s;
}

/* .slide {
  display: inline-block;

  height: 400px;
  width: 100%;
  border-radius: 40px;
} */

/* Buttons */

.slideshowDots {
  text-align: center;
}

.slideshowDot {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;

  cursor: pointer;
  margin: 15px 7px 0px;

  background-color: #c4c4c4;
}

.slideshowDot.active {
  background-color: #6a0dad;
}
